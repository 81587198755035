import { ThresholdPredicate } from "../../../graphql";

export async function calculateInitialThresholdWithFallback(
  calculateInitialThreshold: (
    id: string,
    segmentId: string | undefined,
    p: string,
    predicate?: ThresholdPredicate,
  ) => Promise<number>,
  projectId: string,
  segmentId: string | undefined,
  reportCode: string,
  predicate?: ThresholdPredicate,
  originalValue?: number,
): Promise<[boolean, number | undefined]> {
  try {
    const nvalue = await calculateInitialThreshold(
      projectId,
      segmentId,
      reportCode,
      predicate,
    );
    return [false, nvalue];
  } catch {
    return [true, originalValue];
  }
}
