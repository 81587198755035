import { useMemo } from "react";
import { Typography, useTranslation, useSession } from "@lumar/shared";
import { ModuleCode, RoleCode, ThresholdPredicate } from "../../graphql";
import {
  makeStyles,
  createStyles,
  useTheme,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import {
  FieldArray,
  FieldArrayRenderProps,
  FormikState,
  useFormikContext,
} from "formik";
import { RulesAndThresholdsFormState } from "./utils/types";
import {
  FullReportCategory,
  ReportOption,
} from "../../_common/utils/constants";
import { useInitialReportThresholdCalculator } from "./utils/useInitialReportThresholdCalculator";
import { RulesAndThresholdsTableHeader } from "./RulesAndThresholdsTableHeader";
import clsx from "clsx";
import React from "react";
import { useParams } from "react-router-dom";
import { DEFAULT_REPORT_RULE_AND_THRESHOLDS } from "../create/constants";
import { useTotalRulesCount } from "./utils/useTotalRulesCount";
import { getValidationError } from "./utils/formikHelpers";
import { AddRuleButton } from "./components/AddRuleButton";
import { v4 as uuid } from "uuid";
import { ReportRulesAndThresholdsEditTableRow } from "./ReportRulesAndThresholdsEditTableRow";

export const MAX_NUMBER_OF_RULES = 100;

export function ReportRulesAndThresholdsEditTable(props: {
  reports: ReportOption[];
  categories: FullReportCategory[];
  hasError: boolean;
  projectId: string;
  crawlId: string;
  className?: string;
  moduleCode?: ModuleCode;
}): JSX.Element {
  const { values, errors } = useFormikContext<RulesAndThresholdsFormState>();

  const addPossibleTemplateList = props.reports.filter(
    (element) =>
      !Boolean(
        values.reportRulesAndThresholds.find(
          (e) => e.report?.code === element?.code && !Boolean(e.segment),
        ),
      ),
  );

  const { accountId } = useParams<{ accountId: string }>();
  const { maxRulesCount, totalRulesCount, loading, error } = useTotalRulesCount(
    accountId,
    props.projectId,
  );

  const session = useSession();
  const {
    account: {
      subscription: { segmentationAvailable },
    },
  } = session;

  const classes = useStyles();

  const { t } = useTranslation(["alerts", "units", "common"]);
  const theme = useTheme();

  const [{}, calculateInitialThreshold] = useInitialReportThresholdCalculator();

  const possibleTemplateList = props.reports;

  const readOnly = !session.hasSufficientRole(RoleCode.Editor);

  const sortedList = useMemo(() => {
    // eslint-disable-next-line fp/no-mutating-methods
    return [...(props.reports ?? [])].sort(
      (a, b) => (a.category?.position ?? 0) - (b.category?.position ?? 0),
    );
  }, [props.reports]);

  const accountLimitReached =
    totalRulesCount +
      (values.categoryRulesAndThresholds.length +
        values.reportRulesAndThresholds.length) >=
    maxRulesCount;

  const disableAddButton = loading || Boolean(error) || accountLimitReached;

  const hasReportRules = Boolean(values.reportRulesAndThresholds.length);

  return (
    <div className={clsx(props.className, classes.container)}>
      <Typography variant="subtitle2SemiBold" className={classes.title}>
        {t("alerts:reports")}
      </Typography>
      <div className={classes.scrollableContainer}>
        <FieldArray
          name="reportRulesAndThresholds"
          render={(arrayHelpers) => {
            const { setFieldTouched, setFieldValue, setFormikState } =
              arrayHelpers.form;
            const values = arrayHelpers.form
              .values as RulesAndThresholdsFormState;
            return (
              <>
                {hasReportRules ? (
                  <div className={classes.wrapper}>
                    <Table className={classes.table}>
                      <RulesAndThresholdsTableHeader />
                      <TableBody>
                        {values.reportRulesAndThresholds.map(
                          (ruleAndThreshold, index) => {
                            const reportsErrorMessage = getValidationError(
                              errors.reportRulesAndThresholds,
                              index,
                            );
                            const hasUniqueError =
                              typeof reportsErrorMessage === "string";
                            return (
                              <React.Fragment key={index}>
                                <ReportRulesAndThresholdsEditTableRow
                                  index={index}
                                  ruleAndThreshold={ruleAndThreshold}
                                  readOnly={readOnly}
                                  setFieldTouched={setFieldTouched}
                                  setFieldValue={setFieldValue}
                                  hasUniqueError={hasUniqueError}
                                  calculateInitialThreshold={
                                    calculateInitialThreshold
                                  }
                                  projectId={props.projectId}
                                  segmentationAvailable={segmentationAvailable}
                                  arrayHelpers={arrayHelpers}
                                  crawlId={props.crawlId}
                                  sortedList={sortedList}
                                  hasError={props.hasError}
                                  categories={props.categories}
                                />
                                {hasUniqueError ? (
                                  <TableRow
                                    style={{
                                      height: 25,
                                      boxShadow:
                                        "0px -1px 0px 0px #E5E7EB inset, -1px 0px 0px 0px #E5E7EB inset",
                                    }}
                                  >
                                    <TableCell
                                      colSpan={9}
                                      style={{
                                        background: theme.palette.red[100],
                                        height: 25,
                                        padding: 0,
                                      }}
                                    >
                                      <Typography
                                        style={{
                                          marginLeft: theme.spacing(1.5),
                                        }}
                                        variant="captionSemiBold"
                                      >
                                        {t("common:error")}
                                      </Typography>
                                      {": "}
                                      <Typography variant="caption">
                                        {reportsErrorMessage}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                ) : undefined}
                              </React.Fragment>
                            );
                          },
                        )}
                      </TableBody>
                    </Table>
                  </div>
                ) : undefined}
                <AddRuleButton
                  title={t("alerts:addReportRule")}
                  disabled={
                    !Boolean(possibleTemplateList.length) ||
                    values.reportRulesAndThresholds.length >=
                      MAX_NUMBER_OF_RULES ||
                    disableAddButton
                  }
                  data-testid="add-rule-and-threshold-button"
                  data-pendo="add-rule-and-threshold-button"
                  accountLimitReached={accountLimitReached}
                  onClick={(callback) => {
                    const reportTemplate = addPossibleTemplateList[0];
                    if (reportTemplate) {
                      const uuid = addReportElement(
                        props.moduleCode ?? ModuleCode.Seo,
                        addPossibleTemplateList,
                        arrayHelpers,
                      );
                      calculateInitialThreshold(
                        props.projectId,
                        undefined,
                        reportTemplate.code,
                      ).then((value) => {
                        setFormikState(
                          (state: FormikState<RulesAndThresholdsFormState>) =>
                            getChangedFormicState(state, uuid, value),
                        );
                      });
                      callback();
                      setFieldTouched("reportRulesAndThresholds", true);
                    }
                  }}
                />
              </>
            );
          }}
        />
      </div>
    </div>
  );
}

function getChangedFormicState(
  state: FormikState<RulesAndThresholdsFormState>,
  searchId: string,
  value: number,
): FormikState<RulesAndThresholdsFormState> {
  const elementIndex = state.values.reportRulesAndThresholds.findIndex(
    (e) => e.uuid === searchId,
  );

  if (elementIndex !== -1) {
    return {
      ...state,
      values: {
        ...state.values,
        reportRulesAndThresholds: [
          ...state.values.reportRulesAndThresholds.slice(0, elementIndex),
          {
            ...state.values.reportRulesAndThresholds[elementIndex],
            urls: value,
          },
          ...state.values.reportRulesAndThresholds.slice(elementIndex + 1),
        ],
      },
    };
  }

  return state;
}

function addReportElement(
  moduleCode: ModuleCode,
  addPossibleTemplateList: ReportOption[],
  arrayHelpers: FieldArrayRenderProps,
): string {
  const id = uuid();
  const reportTemplate = addPossibleTemplateList[0];

  const sign = reportTemplate?.totalSign ? reportTemplate?.totalSign : -1;
  arrayHelpers.push({
    ...DEFAULT_REPORT_RULE_AND_THRESHOLDS.find((e) =>
      e.supportedModules?.includes(moduleCode ?? ModuleCode.Seo),
    ),
    report: reportTemplate,
    threshold:
      sign <= 0
        ? ThresholdPredicate.GreaterThanOrEqual
        : ThresholdPredicate.LessThan,
    urls: null,
    uuid: id,
  });
  return id;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    scrollableContainer: {
      overflowX: "hidden",
    },
    table: {
      color: theme.palette.grey[700],
      tableLayout: "auto",
    },
    wrapper: {
      overflow: "auto",
      borderRadius: theme.spacing(1, 1, 0, 0),
      border: `1px solid ${theme.palette.grey[300]}`,
      borderCollapse: "unset",
      background: theme.palette.background.paper,
    },
    title: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: 600,
      lineHeight: theme.typography.pxToRem(18.15),
      marginBottom: theme.spacing(0.5),
    },
    container: {
      display: "flex",
      flexFlow: "column",
    },
  }),
);
