import {
  Snackbar,
  TrashSolid,
  updateIfPropsChanged,
  useTranslation,
} from "@lumar/shared";
import { Segment, ThresholdPredicate } from "../../graphql";
import {
  TextField as MuiTextField,
  makeStyles,
  createStyles,
  Tooltip,
  IconButton,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { FastField, FieldArrayRenderProps, FormikProps } from "formik";
import { Autocomplete } from "formik-material-ui-lab";
import {
  AutocompleteRenderGroupParams,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
} from "@material-ui/lab";
import { HideFromInsufficientRole } from "../../_common/components/HideFromInsufficientRole";
import { SeverityButtons } from "./SeverityButtons";
import { ThresholdPredicateButtons } from "./ThresholdPredicateButtons";
import {
  FullReportCategory,
  ReportOption,
} from "../../_common/utils/constants";
import { LeftBottomPopper } from "../../_common/components/CustomPopper/LeftBottomPopper";
import { ReportOptionElement } from "../../_common/components/reports/components/ReportOptionElement";
import { ReportOptionGroupElement } from "../../_common/components/reports/components/ReportOptionGroupElement";
import clsx from "clsx";
import { SegmentComboBox } from "../../_common/components/SegmentComboBox";
import React from "react";
import {
  REPORT_COMBOBOX_MAX_WIDTH,
  REPORT_COMBOBOX_MIN_WIDTH,
  URL_THRESHOLD_WIDTH,
} from "./table/RulesAndThresholdsTableTitles";
import { RulesAndThresholdsThresholdField } from "./components/RulesAndThresholdsThresholdField";
import { IssuesImprovesCell } from "./components/IssueImprovesCell";
import { IssueWorsensCell } from "./components/IssueWorsensCell";
import { ReportTrendVisualisationCell } from "./components/ReportTrendVisualisationCell";
import { ReportRulesAndThreshold } from "./utils/types";
import { calculateInitialThresholdWithFallback } from "./utils/calculateInitialThresholdWithFallback";
import { enqueueSnackbar } from "notistack";

export const MAX_NUMBER_OF_RULES = 100;

interface Props {
  index: number;
  ruleAndThreshold: ReportRulesAndThreshold;
  readOnly: boolean;
  setFieldTouched: FormikProps<unknown>["setFieldTouched"];
  setFieldValue: FormikProps<unknown>["setFieldValue"];
  hasUniqueError: boolean;
  calculateInitialThreshold: (
    id: string,
    segmentId: string | undefined,
    p: string,
    predicate?: ThresholdPredicate,
  ) => Promise<number>;
  projectId: string;
  crawlId: string;
  segmentationAvailable: boolean;
  arrayHelpers: FieldArrayRenderProps;
  sortedList: ReportOption[];
  hasError: boolean;
  categories: FullReportCategory[];
}

export function ReportRulesAndThresholdsEditTableRow({
  index,
  ruleAndThreshold,
  readOnly,
  setFieldTouched,
  setFieldValue,
  hasUniqueError,
  calculateInitialThreshold,
  projectId,
  segmentationAvailable,
  arrayHelpers,
  crawlId,
  sortedList,
  hasError,
  categories,
}: Props): JSX.Element {
  const [isLoadingSuggestedThreshold, setIsLoadingSuggestedThreshold] =
    React.useState(false);

  const classes = useStyles();
  const { t } = useTranslation(["alerts", "units", "common"]);

  return (
    <TableRow
      className={classes.tableRow}
      data-testid={`rule-and-threshold-row-${index}`}
      data-testid-2={`rule-and-threshold-row-${ruleAndThreshold.report?.name.replace(
        " ",
        "-",
      )}${
        ruleAndThreshold.segment
          ? `-${ruleAndThreshold.segment?.name.replace(" ", "-")}`
          : ""
      }`}
    >
      <TableCell className={classes.rowCell}>
        <SeverityButtons
          isDisabled={readOnly}
          severity={ruleAndThreshold.severity}
          onSeverityChange={(newSeverity) => {
            const fieldName = `reportRulesAndThresholds[${index}].severity`;
            setFieldValue(fieldName, newSeverity);
            setFieldTouched(fieldName, true);
          }}
        />
      </TableCell>
      <TableCell className={classes.rowCell}>
        <FastField
          name={`reportRulesAndThresholds[${index}].report`}
          component={Autocomplete}
          options={sortedList}
          data-testid="rule-and-threshold-report-combobox"
          disableClearable
          disabled={readOnly}
          data-pendo="rule-and-threshold-report-combobox"
          PopperComponent={LeftBottomPopper}
          predicate={ruleAndThreshold.threshold}
          urls={ruleAndThreshold.urls}
          error={hasUniqueError ? "" : undefined}
          shouldUpdate={updateIfPropsChanged([
            "predicate",
            "urls",
            "error",
            "sortedList",
          ])}
          classes={{
            paper: classes.paper,
            option: classes.option,
          }}
          onFocus={() =>
            setFieldTouched(`reportRulesAndThresholds[${index}].report`, true)
          }
          renderOption={(
            option: ReportOption,
            state: AutocompleteRenderOptionState,
          ) => {
            return (
              <ReportOptionElement
                key={option.code}
                option={option}
                state={state}
                maxSelection={1}
                group={option.category?.code}
                parentGroup={option.category?.parentName}
              />
            );
          }}
          noOptionsText={
            <span data-testid="report-combobox-no-options-text">
              {hasError ? t("alerts:reportsError") : t("common:noOptions")}
            </span>
          }
          getOptionLabel={(option: ReportOption) => {
            if (option.code.startsWith("custom_extraction_")) {
              return (
                sortedList.find((e) => e.code === option.code)?.name ??
                option.name
              );
            }
            return option.name;
          }}
          getOptionSelected={(option: ReportOption, value: ReportOption) =>
            option.code === value.code
          }
          style={{
            minWidth: REPORT_COMBOBOX_MIN_WIDTH,
            flex: 1,
            maxWidth: REPORT_COMBOBOX_MAX_WIDTH,
          }}
          onChange={async (_: unknown, o: ReportOption) => {
            if (!readOnly) {
              setIsLoadingSuggestedThreshold(true);
              const [error, nvalue] =
                await calculateInitialThresholdWithFallback(
                  calculateInitialThreshold,
                  projectId,
                  ruleAndThreshold.segment?.id,
                  o.code,
                  ruleAndThreshold.threshold,
                  ruleAndThreshold.urls,
                );
              if (error)
                enqueueSnackbar(
                  <Snackbar
                    variant="error"
                    title={t("alerts:thresholdCalculationFailure")}
                  />,
                );

              const urlField = `reportRulesAndThresholds[${index}].urls`;
              setFieldValue(urlField, nvalue);
              setFieldTouched(urlField, true);
              const reportName = `reportRulesAndThresholds[${index}].report`;
              setFieldValue(reportName, o);
              setFieldTouched(reportName, true);
              setIsLoadingSuggestedThreshold(false);
            }
          }}
          renderGroup={(option: AutocompleteRenderGroupParams) => (
            <ReportOptionGroupElement
              key={option.key}
              group={option}
              categories={categories}
            />
          )}
          groupBy={(option: ReportOption) =>
            option.category?.parentName?.length
              ? option.category?.parentName
              : "SEO"
          }
          renderInput={(
            params: Exclude<AutocompleteRenderInputParams, "className">,
          ) => (
            <MuiTextField
              {...params}
              className={classes.textField}
              name={`reportRulesAndThresholds[${index}].report`}
              error={hasUniqueError}
              variant="outlined"
            />
          )}
        />
      </TableCell>
      {segmentationAvailable && (
        <TableCell className={classes.rowCell}>
          <FastField
            data-pendo="rule-and-threshold-segment-combobox"
            name={`reportRulesAndThresholds[${index}].segment`}
            size="small"
            component={SegmentComboBox}
            projectId={projectId}
            report={ruleAndThreshold.report}
            urls={ruleAndThreshold.urls}
            predicate={ruleAndThreshold.threshold}
            error={hasUniqueError}
            selected={ruleAndThreshold.segment}
            classes={{
              input: classes.segmentTextField,
            }}
            shouldUpdate={updateIfPropsChanged([
              "error",
              "predicate",
              "urls",
              "report",
              "projectId",
            ])}
            onChange={async (e: Pick<Segment, "id" | "name">) => {
              if (!readOnly) {
                setIsLoadingSuggestedThreshold(true);
                const [error, nvalue] =
                  await calculateInitialThresholdWithFallback(
                    calculateInitialThreshold,
                    projectId,
                    e?.id,
                    ruleAndThreshold.report.code,
                    ruleAndThreshold.threshold,
                    ruleAndThreshold.urls,
                  );
                if (error)
                  enqueueSnackbar(
                    <Snackbar
                      variant="error"
                      title={t("alerts:thresholdCalculationFailure")}
                    />,
                  );

                const urlFieldName = `reportRulesAndThresholds[${index}].urls`;
                setFieldValue(urlFieldName, nvalue);
                setFieldTouched(urlFieldName, true);
                const urlSegmentName = `reportRulesAndThresholds[${index}].segment`;
                setFieldValue(urlSegmentName, e);
                setFieldTouched(urlSegmentName, true);
                setIsLoadingSuggestedThreshold(false);
              }
            }}
          />
        </TableCell>
      )}
      <TableCell className={classes.rowCell}>
        <ThresholdPredicateButtons
          isDisabled={readOnly || isLoadingSuggestedThreshold}
          threshold={ruleAndThreshold.threshold}
          report={ruleAndThreshold.report}
          urls={ruleAndThreshold.urls}
          segmentId={ruleAndThreshold.segment?.id}
          onThresholdChange={async (newThreshold) => {
            if (!readOnly) {
              const cvalue = await calculateInitialThreshold(
                projectId,
                ruleAndThreshold.segment?.id,
                ruleAndThreshold.report.code,
                ruleAndThreshold.threshold,
              );
              if (cvalue === ruleAndThreshold.urls) {
                const nvalue = await calculateInitialThreshold(
                  projectId,
                  ruleAndThreshold.segment?.id,
                  ruleAndThreshold.report.code,
                  newThreshold,
                );
                const fieldName = `reportRulesAndThresholds[${index}].urls`;
                setFieldValue(fieldName, nvalue);
                setFieldTouched(fieldName, true);
              }
              const fieldName = `reportRulesAndThresholds[${index}].threshold`;
              setFieldValue(fieldName, newThreshold);
              setFieldTouched(fieldName, true);
            }
          }}
        />
      </TableCell>
      <TableCell className={clsx(classes.rowCell, classes.thresholdCell)}>
        <RulesAndThresholdsThresholdField
          readOnly={readOnly || isLoadingSuggestedThreshold}
          arrayHelpers={arrayHelpers}
          name={`reportRulesAndThresholds[${index}].urls`}
          ruleAndThreshold={ruleAndThreshold}
          data-testid="rule-and-threshold-url-input"
          data-pendo="rule-and-threshold-url-input"
        />
      </TableCell>

      <IssuesImprovesCell
        name={`reportRulesAndThresholds[${index}].thresholdAcceptanceWhenBetter`}
        onChange={(e) => {
          const fieldName = `reportRulesAndThresholds[${index}].thresholdAcceptanceWhenBetter`;
          setFieldValue(fieldName, e.target.value);
          setFieldTouched(fieldName, true);
        }}
      />
      <IssueWorsensCell
        name={`reportRulesAndThresholds[${index}].thresholdAcceptanceWhenWorse`}
        onChange={(e) => {
          const fieldName = `reportRulesAndThresholds[${index}].thresholdAcceptanceWhenWorse`;
          setFieldValue(fieldName, e.target.value);
          setFieldTouched(fieldName, true);
        }}
      />

      <ReportTrendVisualisationCell
        projectId={projectId}
        ruleAndThreshold={ruleAndThreshold}
        crawlId={crawlId ?? ""}
      />
      <TableCell className={classes.rowCell}>
        <HideFromInsufficientRole>
          <Tooltip title="Remove" arrow={false}>
            <IconButton
              className={classes.iconButton}
              size="small"
              onClick={() => {
                arrayHelpers.remove(index);
                setFieldTouched("reportRulesAndThresholds", true);
              }}
              data-testid="remove-rule-and-threshold"
              data-pendo="remove-rule-and-threshold"
              aria-label={t("alerts:deleteTest")}
            >
              <TrashSolid />
            </IconButton>
          </Tooltip>
        </HideFromInsufficientRole>
      </TableCell>
    </TableRow>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    textField: {
      "& input": {
        height: 17,
        paddingTop: "6px!important",
        paddingBottom: "6px!important",
      },
      "& .Mui-disabled": {
        color: theme.palette.grey[400],
      },
      minWidth: URL_THRESHOLD_WIDTH,
      padding: "0!important",
    },
    segmentTextField: {
      "& input::placeholder": {
        color: theme.palette.grey[800],
      },
      "& input": {
        height: 17,
        paddingTop: "6px!important",
        paddingBottom: "6px!important",
        color: theme.palette.grey[800],
      },
      "& .Mui-disabled": {
        color: theme.palette.grey[400],
      },
      color: theme.palette.grey[800],
      paddingTop: "0!important",
      paddingBottom: "0!important",
    },
    iconButton: {
      marginTop: 2,
      borderRadius: 6,
      "& svg": {
        fontSize: 18,
        color: theme.palette.grey[400],
      },
      "&:hover": {
        backgroundColor: theme.palette.red[100],
        "& svg": {
          color: theme.palette.red[600],
        },
      },
    },
    paper: {
      paddingLeft: "0",
      paddingRight: "0",
    },
    option: {
      padding: 0,
      "&[aria-disabled='true']": {
        pointerEvents: "all!important",
        cursor: "default",
      },
      "&[aria-selected='true']": {
        color: theme.palette.grey[700],
        backgroundColor: theme.palette.ultraviolet[200],
        "&:hover": {
          backgroundColor: theme.palette.grey[200],
        },
      },
    },
    tableRow: {
      boxShadow: `0px -1px 0px 0px #E5E7EB inset, -1px 0px 0px 0px #E5E7EB inset`,
      height: 56,
    },
    rowCell: {
      boxShadow: `-1px 0px 0px 0px #E5E7EB inset`,
      padding: theme.spacing(1.5, 1.55),
      color: theme.palette.grey[700],
    },
    thresholdCell: {
      width: "12%",
    },
  }),
);
