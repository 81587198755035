import {
  Snackbar,
  TrashSolid,
  updateIfPropsChanged,
  useTranslation,
} from "@lumar/shared";
import { Segment, ThresholdPredicate } from "../../graphql";
import {
  makeStyles,
  createStyles,
  Tooltip,
  IconButton,
  TableRow,
  TableCell,
} from "@material-ui/core";
import {
  FastField,
  FieldArrayRenderProps,
  FieldProps,
  FormikErrors,
  FormikProps,
} from "formik";
import {
  HealthScoreRulesAndThreshold,
  RulesAndThresholdsFormState,
} from "./utils/types";
import { HideFromInsufficientRole } from "../../_common/components/HideFromInsufficientRole";
import { SeverityButtons } from "./SeverityButtons";
import { ThresholdPredicateButtons } from "./ThresholdPredicateButtons";
import { ReportCategoryOption } from "../../_common/utils/constants";
import { LeftBottomPopper } from "../../_common/components/CustomPopper/LeftBottomPopper";
import clsx from "clsx";
import { SegmentComboBox } from "../../_common/components/SegmentComboBox";
import React from "react";
import { ReportCategoriesCombobox } from "../../_common/components/reports/ReportCategoriesCombobox";
import { RulesAndThresholdsThresholdField } from "./components/RulesAndThresholdsThresholdField";
import { IssuesImprovesCell } from "./components/IssueImprovesCell";
import { IssueWorsensCell } from "./components/IssueWorsensCell";
import { HealthScoreTrendVisualisationCell } from "./components/HealthScoreTrendVisualisationCell";
import { calculateInitialThresholdWithFallback } from "./utils/calculateInitialThresholdWithFallback";
import { enqueueSnackbar } from "notistack";

interface Props {
  index: number;
  ruleAndThreshold: HealthScoreRulesAndThreshold;
  readOnly: boolean;
  setFieldTouched: FormikProps<unknown>["setFieldTouched"];
  setFieldValue: FormikProps<unknown>["setFieldValue"];
  hasUniqueError: boolean;
  categoriesLoading: boolean;
  options: {
    id: string;
    code: string;
    name: string;
    description: string | undefined;
    level: number;
  }[];
  errors: FormikErrors<RulesAndThresholdsFormState>;
  calculateInitialThreshold: (
    id: string,
    segmentId: string | undefined,
    p: string,
    predicate?: ThresholdPredicate,
  ) => Promise<number>;
  projectId: string;
  crawlId: string;
  segmentationAvailable: boolean;
  arrayHelpers: FieldArrayRenderProps;
}

export function CategoryRulesAndThresholdsEditTableRow({
  index,
  ruleAndThreshold,
  readOnly,
  setFieldTouched,
  setFieldValue,
  hasUniqueError,
  categoriesLoading,
  options,
  errors,
  calculateInitialThreshold,
  projectId,
  segmentationAvailable,
  arrayHelpers,
  crawlId,
}: Props): JSX.Element {
  const [isLoadingSuggestedThreshold, setIsLoadingSuggestedThreshold] =
    React.useState(false);

  const classes = useStyles();
  const { t } = useTranslation(["alerts", "units", "common"]);

  return (
    <TableRow
      className={classes.tableRow}
      data-testid={`category-rule-and-threshold-row-${index}`}
      data-testid-2={`category-rule-and-threshold-row-${ruleAndThreshold.report?.name.replace(
        " ",
        "-",
      )}${
        ruleAndThreshold.segment
          ? `-${ruleAndThreshold.segment?.name.replace(" ", "-")}`
          : ""
      }`}
    >
      <TableCell className={classes.rowCell}>
        <SeverityButtons
          isDisabled={readOnly}
          severity={ruleAndThreshold.severity}
          onSeverityChange={(newSeverity) => {
            const fieldName = `categoryRulesAndThresholds[${index}].severity`;
            setFieldValue(fieldName, newSeverity);
            setFieldTouched(fieldName, true);
          }}
        />
      </TableCell>
      <TableCell className={classes.rowCell}>
        <FastField
          name={`categoryRulesAndThresholds[${index}].report`}
          shouldUpdate={updateIfPropsChanged([
            "predicate",
            "urls",
            "error",
            "sortedList",
            "loading",
          ])}
          report={ruleAndThreshold.report}
          urls={ruleAndThreshold.urls}
          predicate={ruleAndThreshold.threshold}
          error={hasUniqueError ? "" : undefined}
          loading={categoriesLoading}
        >
          {(fieldProps: FieldProps<ReportCategoryOption>) => {
            const error =
              hasUniqueError ||
              Boolean(
                Array.isArray(errors) ? errors[index]?.["report"] : false,
              );

            return (
              <ReportCategoriesCombobox
                options={options}
                size="small"
                data-testid="rule-and-threshold-report-combobox"
                disableClearable
                disabled={readOnly}
                value={fieldProps.field.value}
                id={fieldProps.field.name}
                data-pendo="rule-and-threshold-report-combobox"
                PopperComponent={LeftBottomPopper}
                onBlur={fieldProps.field.onBlur}
                maxWidth={1000}
                error={error}
                classes={{
                  paper: classes.paper,
                  option: classes.option,
                }}
                onFocus={() =>
                  setFieldTouched(
                    `categoryRulesAndThresholds[${index}].report`,
                    true,
                  )
                }
                loading={categoriesLoading}
                multiple={false}
                onChange={async (o) => {
                  if (!readOnly) {
                    setIsLoadingSuggestedThreshold(true);
                    const [error, nvalue] =
                      await calculateInitialThresholdWithFallback(
                        calculateInitialThreshold,
                        projectId,
                        ruleAndThreshold.segment?.id,
                        o.code,
                        ruleAndThreshold.threshold,
                        ruleAndThreshold.urls,
                      );
                    if (error)
                      enqueueSnackbar(
                        <Snackbar
                          variant="error"
                          title={t("alerts:thresholdCalculationFailure")}
                        />,
                      );

                    const urlField = `categoryRulesAndThresholds[${index}].urls`;
                    setFieldValue(urlField, nvalue);
                    setFieldTouched(urlField, true);
                    const reportName = `categoryRulesAndThresholds[${index}].report`;
                    setFieldValue(reportName, o);
                    setFieldTouched(reportName, true);
                    setIsLoadingSuggestedThreshold(false);
                  }
                }}
              />
            );
          }}
        </FastField>
      </TableCell>
      {segmentationAvailable && (
        <TableCell className={classes.rowCell}>
          <FastField
            data-pendo="category-rule-and-threshold-segment-combobox"
            name={`categoryRulesAndThresholds[${index}].segment`}
            size="small"
            component={SegmentComboBox}
            projectId={projectId}
            report={ruleAndThreshold.report}
            urls={ruleAndThreshold.urls}
            predicate={ruleAndThreshold.threshold}
            error={hasUniqueError}
            selected={ruleAndThreshold.segment}
            classes={{
              input: classes.segmentTextField,
            }}
            shouldUpdate={updateIfPropsChanged([
              "error",
              "predicate",
              "urls",
              "report",
              "projectId",
            ])}
            onChange={async (e: Pick<Segment, "id" | "name">) => {
              if (!readOnly) {
                setIsLoadingSuggestedThreshold(true);
                const [error, nvalue] =
                  await calculateInitialThresholdWithFallback(
                    calculateInitialThreshold,
                    projectId,
                    e?.id,
                    ruleAndThreshold.report.code,
                    ruleAndThreshold.threshold,
                    ruleAndThreshold.urls,
                  );
                if (error)
                  enqueueSnackbar(
                    <Snackbar
                      variant="error"
                      title={t("alerts:thresholdCalculationFailure")}
                    />,
                  );

                const urlFieldName = `categoryRulesAndThresholds[${index}].urls`;
                setFieldValue(urlFieldName, nvalue);
                setFieldTouched(urlFieldName, true);
                const urlSegmentName = `categoryRulesAndThresholds[${index}].segment`;
                setFieldValue(urlSegmentName, e);
                setFieldTouched(urlSegmentName, true);
                setIsLoadingSuggestedThreshold(false);
              }
            }}
          />
        </TableCell>
      )}
      <TableCell className={classes.rowCell}>
        <ThresholdPredicateButtons
          isDisabled={readOnly || isLoadingSuggestedThreshold}
          threshold={ruleAndThreshold.threshold}
          report={ruleAndThreshold.report}
          segmentId={ruleAndThreshold.segment?.id}
          urls={ruleAndThreshold.urls}
          onThresholdChange={async (newThreshold) => {
            if (!readOnly) {
              const cvalue = await calculateInitialThreshold(
                projectId,
                ruleAndThreshold.segment?.id,
                ruleAndThreshold.report.code,
                ruleAndThreshold.threshold,
              );
              if (cvalue === ruleAndThreshold.urls) {
                const nvalue = await calculateInitialThreshold(
                  projectId,
                  ruleAndThreshold.segment?.id,
                  ruleAndThreshold.report.code,
                  newThreshold,
                );
                const fieldName = `categoryRulesAndThresholds[${index}].urls`;
                setFieldValue(fieldName, nvalue);
                setFieldTouched(fieldName, true);
              }
              const fieldName = `categoryRulesAndThresholds[${index}].threshold`;
              setFieldValue(fieldName, newThreshold);
              setFieldTouched(fieldName, true);
            }
          }}
        />
      </TableCell>
      <TableCell className={clsx(classes.rowCell, classes.thresholdCell)}>
        <RulesAndThresholdsThresholdField
          readOnly={readOnly || isLoadingSuggestedThreshold}
          arrayHelpers={arrayHelpers}
          name={`categoryRulesAndThresholds[${index}].urls`}
          ruleAndThreshold={ruleAndThreshold}
          unit="score"
          data-testid="category-rule-and-threshold-url-input"
          data-pendo="category-rule-and-threshold-url-input"
        />
      </TableCell>

      <IssuesImprovesCell
        name={`categoryRulesAndThresholds[${index}].thresholdAcceptanceWhenBetter`}
        onChange={(e) => {
          const fieldName = `categoryRulesAndThresholds[${index}].thresholdAcceptanceWhenBetter`;
          setFieldValue(fieldName, e.target.value);
          setFieldTouched(fieldName, true);
        }}
      />
      <IssueWorsensCell
        name={`categoryRulesAndThresholds[${index}].thresholdAcceptanceWhenWorse`}
        onChange={(e) => {
          const fieldName = `categoryRulesAndThresholds[${index}].thresholdAcceptanceWhenWorse`;
          setFieldValue(fieldName, e.target.value);
          setFieldTouched(fieldName, true);
        }}
      />

      <HealthScoreTrendVisualisationCell
        projectId={projectId}
        ruleAndThreshold={ruleAndThreshold}
        crawlId={crawlId ?? ""}
      />
      <TableCell className={classes.rowCell}>
        <HideFromInsufficientRole>
          <Tooltip title="Remove" arrow={false}>
            <IconButton
              className={classes.iconButton}
              size="small"
              onClick={() => {
                arrayHelpers.remove(index);
                setFieldTouched("categoryRulesAndThresholds", true);
              }}
              data-testid="remove-rule-and-threshold"
              data-pendo="remove-rule-and-threshold"
              aria-label={t("alerts:deleteTest")}
            >
              <TrashSolid />
            </IconButton>
          </Tooltip>
        </HideFromInsufficientRole>
      </TableCell>
    </TableRow>
  );
}

const useStyles = makeStyles((theme) =>
  createStyles({
    segmentTextField: {
      "& input::placeholder": {
        color: theme.palette.grey[800],
      },
      "& input": {
        height: 17,
        paddingTop: "6px!important",
        paddingBottom: "6px!important",
        color: theme.palette.grey[800],
      },
      "& .Mui-disabled": {
        color: theme.palette.grey[400],
      },
      color: theme.palette.grey[800],
      paddingTop: "0!important",
      paddingBottom: "0!important",
    },
    iconButton: {
      marginTop: 2,
      borderRadius: 6,
      "& svg": {
        fontSize: 18,
        color: theme.palette.grey[400],
      },
      "&:hover": {
        backgroundColor: theme.palette.red[100],
        "& svg": {
          color: theme.palette.red[600],
        },
      },
    },
    paper: {
      paddingLeft: "0",
      paddingRight: "0",
    },
    option: {
      padding: 0,
      "&[aria-disabled='true']": {
        pointerEvents: "all!important",
        cursor: "default",
      },
      "&[aria-selected='true']": {
        color: theme.palette.grey[700],
        backgroundColor: theme.palette.ultraviolet[200],
        "&:hover": {
          backgroundColor: theme.palette.grey[200],
        },
      },
    },
    tableRow: {
      boxShadow: `0px -1px 0px 0px #E5E7EB inset, -1px 0px 0px 0px #E5E7EB inset`,
      height: 56,
    },
    rowCell: {
      boxShadow: `-1px 0px 0px 0px #E5E7EB inset`,
      padding: theme.spacing(1.5, 1.55),
      color: theme.palette.grey[700],
    },
    thresholdCell: {
      width: "12%",
    },
  }),
);
