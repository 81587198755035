import DateFnsUtils from "@date-io/date-fns";
import {
  AppThemeProvider,
  BrandProvider,
  ErrorDisplay,
  GTMSetup,
  HelpScoutStyleOverwrite,
  I18nProvider,
} from "@lumar/shared";
import { CssBaseline } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  ErrorBoundary as RollbarErrorBoundary,
  LEVEL_ERROR,
  Provider as RollbarProvider,
} from "@rollbar/react";
import ReactDOM from "react-dom";
import { App } from "./app";
import { rollbarConfig } from "./rollbarConfig";
import { resources } from "./_common/locale/resources";

ReactDOM.render(
  <RollbarProvider config={rollbarConfig}>
    <RollbarErrorBoundary level={LEVEL_ERROR} fallbackUI={ErrorDisplay}>
      <BrandProvider>
        <GTMSetup />
        <I18nProvider resources={resources}>
          <AppThemeProvider>
            <CssBaseline />
            <HelpScoutStyleOverwrite />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <App />
            </MuiPickersUtilsProvider>
          </AppThemeProvider>
        </I18nProvider>
      </BrandProvider>
    </RollbarErrorBoundary>
  </RollbarProvider>,
  document.getElementById("root"),
);
