import { SimpleTestResult } from "../../alerts/_common/utils/types";
import { MonitorNotificationType } from "../types";

export function extractChartDataFromTestResults(
  thresholdTrend: Array<SimpleTestResult> | undefined,
  notificationType: MonitorNotificationType,
): [number, number][] {
  if (thresholdTrend?.length) {
    return thresholdTrend
      .filter((e) => e.createdAt?.length && e.absoluteThreshold !== undefined)
      .map((trend) => [
        (trend.createdAt?.length
          ? new Date(trend.createdAt)
          : new Date()
        ).getTime(),
        getAbsoluteThreshold(trend, notificationType),
      ]);
  }

  return [];
}

function getAbsoluteThreshold(
  trend: SimpleTestResult,
  notificationType: MonitorNotificationType,
): number {
  if (!trend.absoluteThreshold) return 0;
  if (notificationType === MonitorNotificationType.HealthScore)
    return trend.absoluteThreshold * 100;
  return trend.absoluteThreshold;
}
