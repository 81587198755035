import React from "react";
import {
  Typography,
  ExclamationSolid,
  ExclamationCircleSolid,
  ArrowCircleRightSolid,
  CheckCircleSolid,
  ChartPieSolid,
  useNumberFormatter,
  useTranslation,
  useSession,
  ApolloError,
} from "@lumar/shared";
import {
  makeStyles,
  Tooltip,
  Chip as MuiChip,
  useTheme,
} from "@material-ui/core";
import { SparklinesMiniChart } from "../../_common/components/SparklinesMiniChart/SparklinesMiniChart";
import { ConditionalLink } from "../_common/ConditionalLink";
import { EditRuleChip } from "../_common/EditRuleChip";
import { ManageAlertButton } from "../_common/ManageAlertButton";
import { StatusChips } from "../_common/StatusChips";
import { SuggestedThreshold } from "../_common/SuggestedThreshold";
import { extractChartDataFromTrends } from "../utils/extractChartDataFromTrends";
import { assert } from "../../_common/assert";
import {
  GetRulesAndThresholdsQuery,
  ReportTemplateUnit,
  Severity,
  TestAutoThresholdAcceptance,
  TestResultStatus,
  ThresholdPredicate,
  useGetRulesAndThresholdsQuery,
} from "../../graphql";
import clsx from "clsx";
import { MonitorNotification, MonitorNotificationType } from "../types";
import { useSuggestedThreshold } from "../utils/useSuggestedThreshold";
import { extractChartDataFromTestResults } from "../utils/extractChartDataFromTestResults";
import { minBy, maxBy } from "lodash";
import { useInView } from "react-intersection-observer";
import { NotificationTitle } from "./NotificationTitle";
import { ProjectInformation } from "./ProjectInformation";

const BORDER_RADIUS = 8;

interface Props {
  notification: MonitorNotification;
  selected?: boolean;
  isAdjustment: boolean;
  noUnitOnChange?: boolean;
  onRuleEdit?: (notification: MonitorNotification) => void;
  handleAcceptSuggestedThreshold: (
    notification: MonitorNotification,
    value: number,
  ) => Promise<void>;
  handleDeclineSuggestedThreshold: (
    notification: MonitorNotification,
  ) => Promise<void>;
  handleStatusUpdate: (
    notification: MonitorNotification,
    status: TestResultStatus,
  ) => Promise<ApolloError | undefined>;
  "data-pendo"?: string;
  formatUrl: (notification: MonitorNotification) => {
    url: string;
    tooltip: string;
  };
}

export function NotificationItem({
  notification,
  selected,
  isAdjustment,
  onRuleEdit,
  handleAcceptSuggestedThreshold,
  handleDeclineSuggestedThreshold,
  noUnitOnChange,
  formatUrl,
  ...props
}: Props): JSX.Element {
  const { severity, absoluteThreshold, thresholdPredicate, stat, base } =
    notification;

  assert(base);
  assert(thresholdPredicate);

  const {
    account: {
      subscription: { segmentationAvailable },
    },
  } = useSession();

  const urlThreshold = absoluteThreshold ?? 0;
  const isFailed = severity === Severity.Fail;

  const [isMouseOver, setIsMouseOver] = React.useState(false);
  const [showArrowTooltip, setShowArrowTooltip] = React.useState(false);

  const suggestedThreshold = useSuggestedThreshold(notification);

  const classes = useStyles({ isFailed, isAdjustment });

  const { t } = useTranslation([
    "common",
    "alerts",
    "notifications",
    "errors",
    "units",
  ]);
  const theme = useTheme();
  const formatNumber = useNumberFormatter();

  const { lastCrawledAt, currentUrlCount, previousUrlCount, seriesData } =
    extractChartDataFromTrends(stat?.trend);

  const thresholdSeriesData = extractChartDataFromTestResults(
    stat?.testResults?.nodes,
    notification.type,
  );

  const { data } = useGetRulesAndThresholdsQuery({
    variables: {
      projectId: stat?.project.id,
    },
    skip: !Boolean(stat?.project.id),
  });

  const doesAlertStillExist = doesHaveAnyRulesAndThresholds(
    notification.type,
    data,
  );

  const differenceFromRule = currentUrlCount - urlThreshold;
  const doesRuleStillExist = Boolean(notification.test);

  const hasSuggestedThreshold =
    (suggestedThreshold.thresholdAcceptanceWorse ===
      TestAutoThresholdAcceptance.Suggest &&
      typeof suggestedThreshold.value === "number" &&
      !isAdjustment) ||
    (suggestedThreshold.thresholdAcceptanceBetter ===
      TestAutoThresholdAcceptance.Suggest &&
      typeof suggestedThreshold.value === "number" &&
      isAdjustment);

  const hasThresholdBeenUpdated =
    urlThreshold !== notification.test?.absoluteThreshold ||
    thresholdPredicate !== notification.test.thresholdPredicate;

  const shouldShowSuggestedThreshold =
    hasSuggestedThreshold &&
    suggestedThreshold.isVisible &&
    !(suggestedThreshold.isAccepted || suggestedThreshold.isRejected);

  const showSuggestionChip =
    notification.automaticThresholdAcceptanceWhenTestResultIsWorse &&
    notification.automaticThresholdAcceptanceWhenTestResultIsWorse !==
      TestAutoThresholdAcceptance.None &&
    !shouldShowSuggestedThreshold;

  const { ref, inView } = useInView({
    delay: 0,
    rootMargin: "1000px 0px 1000px 0px",
    threshold: 0,
    fallbackInView: true,
  });

  const isCustomExtraction = base.code.startsWith("custom_extraction_");
  const reportTemplateName =
    (isCustomExtraction
      ? stat?.customExtractions?.find((e) => e.reportTemplateCode === base.code)
          ?.label
      : undefined) ?? base.name;

  const urlData = formatUrl(notification);
  return (
    <>
      <ConditionalLink
        href={urlData.url}
        shouldRenderAsLink={Boolean(stat)}
        className={classes.link}
        data-testid="notification-list-item"
        {...props}
      >
        <div
          ref={ref}
          data-testid="notification-list-item-hoverable-area"
          className={clsx(
            classes.container,
            selected && classes.bluebackground,
          )}
          onMouseEnter={() => setIsMouseOver(true)}
          onMouseLeave={() => setIsMouseOver(false)}
        >
          <div className={classes.statusIconContainer}>
            {isAdjustment ? (
              <CheckCircleSolid />
            ) : isFailed ? (
              <ExclamationCircleSolid data-testid="failed-icon" />
            ) : (
              <ExclamationSolid data-testid="warning-icon" />
            )}
          </div>
          <div className={classes.centralContainer}>
            <div className={classes.mainContentContainer}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className={classes.titleContainer}>
                  <NotificationTitle
                    isFailed={isFailed}
                    reportName={reportTemplateName}
                    previousUrlCount={previousUrlCount}
                    currentUrlCount={currentUrlCount}
                    isHovering={isMouseOver}
                    isAdjustment={isAdjustment}
                    unit={notification.unit ?? ReportTemplateUnit.UrLs}
                    noUnitOnChange={noUnitOnChange}
                  />

                  <Typography
                    variant="caption"
                    className={classes.secondaryText}
                    style={{ marginBottom: 5 }}
                  >
                    <strong style={{ fontWeight: 600 }}>
                      {formatNumber(Math.abs(differenceFromRule)) + " "}
                    </strong>
                    {t("notifications:urlsThan", {
                      rule: differenceFromRule >= 0 ? "more" : "less",
                      unit: noUnitOnChange
                        ? ""
                        : t(
                            `units:${notification.unit ?? ReportTemplateUnit.UrLs}`,
                            {
                              count: Math.abs(differenceFromRule),
                            },
                          ),
                    })}
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: theme.spacing(1),
                      gap: 12,
                    }}
                  >
                    <EditRuleChip
                      absoluteThreshold={urlThreshold}
                      thresholdPredicate={thresholdPredicate}
                      onClick={() => {
                        onRuleEdit?.(notification);
                      }}
                      doesRuleStillExist={doesRuleStillExist}
                      unit={notification.unit ?? ReportTemplateUnit.UrLs}
                      disabled={!onRuleEdit}
                    />
                    {!doesRuleStillExist || hasThresholdBeenUpdated ? (
                      <Typography className={classes.updatedRuleText}>
                        {doesRuleStillExist
                          ? t("notifications:ruleUpdatedTo", {
                              count: notification.test?.absoluteThreshold ?? 0,
                              symbol:
                                notification.test?.thresholdPredicate ===
                                ThresholdPredicate.GreaterThanOrEqual
                                  ? "≥"
                                  : "<",
                              unit: t(
                                `units:${notification.unit ?? ReportTemplateUnit.UrLs}`,
                                {
                                  count:
                                    notification.test?.absoluteThreshold ?? 0,
                                },
                              ),
                            })
                          : t("notifications:ruleDeleted")}
                      </Typography>
                    ) : null}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: theme.spacing(1),
                    }}
                  >
                    {showSuggestionChip ? (
                      <MuiChip
                        label={
                          suggestedThreshold.thresholdAcceptanceWorse ===
                          TestAutoThresholdAcceptance.Suggest
                            ? t("notifications:suggestedThresholdEnabled")
                            : t("notifications:autoThresholdEnabled")
                        }
                        className={classes.suggestedThresholdChip}
                      />
                    ) : null}
                    <Typography
                      variant="captionMedium"
                      className={classes.secondaryText}
                      data-testid="notification-timestamp"
                    >
                      {lastCrawledAt
                        ? t("common:dateAndTime", { date: lastCrawledAt })
                        : t("common:unknown")}
                    </Typography>
                  </div>
                  {inView ? (
                    <div style={{ display: "flex", alignItems: "flex-end" }}>
                      <div style={{ marginLeft: 16 }}>
                        <SparklinesMiniChart
                          height={55}
                          width={185}
                          minX={minBy(seriesData, (e) => e[0])?.[0] ?? 0}
                          maxX={maxBy(seriesData, (e) => e[0])?.[0] ?? 1}
                          data={[
                            {
                              values: seriesData,
                              type: "area",
                              color: theme.palette.grey[600],
                              gradient: [
                                [0, theme.palette.grey[100]],
                                [1, theme.palette.grey[100]],
                              ],
                            },
                            {
                              values: thresholdSeriesData,
                              color: "#EF4444",
                              gradient: null,
                              disableMarker: true,
                              dashStyle: "ShortDash",
                              lineWidth: 1,
                            },
                          ]}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "baseline",
                  minHeight: 40,
                }}
              >
                {stat ? (
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    <ProjectInformation
                      name={stat.project.name}
                      primaryDomain={stat.project.primaryDomain}
                    />
                    {segmentationAvailable && (
                      <>
                        <div
                          style={{
                            marginLeft: theme.spacing(1.375),
                            marginRight: theme.spacing(1.375),
                            width: 1,
                            background: theme.palette.grey[300],
                          }}
                        />
                        <Tooltip
                          title={
                            <div>
                              <Typography
                                variant="h1Bold"
                                style={{
                                  fontSize: theme.typography.pxToRem(11),
                                }}
                              >
                                {t("notifications:segment")}
                              </Typography>
                              <div>
                                {notification.segment?.name ??
                                  t("common:noSegments")}
                              </div>
                            </div>
                          }
                          arrow={false}
                        >
                          <div className={classes.segment}>
                            <ChartPieSolid
                              style={{
                                width: 20,
                                height: 20,
                                marginRight: theme.spacing(1),
                              }}
                            />
                            <Typography
                              variant="captionSemiBold"
                              className={classes.segmentText}
                            >
                              {notification.segment?.name ??
                                t("common:noSegments")}
                            </Typography>
                          </div>
                        </Tooltip>
                      </>
                    )}
                  </div>
                ) : null}
                <div
                  // Note: stops the user from being redirected to analyze app if they try to click the button/chips and miss
                  onClick={(e) => e.preventDefault()}
                  id="manage-alert-zone"
                  className={classes.statusChipsContainer}
                >
                  <div style={{ marginLeft: "auto", marginBottom: -7 }} />

                  {isMouseOver ? (
                    <>
                      {stat ? (
                        <ManageAlertButton
                          alertId={stat.project.id}
                          disabled={!doesAlertStillExist}
                        />
                      ) : null}
                      {!isAdjustment ? (
                        <StatusChips
                          initialStatus={notification.status}
                          onChange={async (status) => {
                            return props.handleStatusUpdate(
                              notification,
                              status,
                            );
                          }}
                        />
                      ) : null}
                    </>
                  ) : null}
                </div>
              </div>
            </div>
            {shouldShowSuggestedThreshold ? (
              <SuggestedThreshold
                notification={notification}
                onAccept={handleAcceptSuggestedThreshold}
                onDecline={handleDeclineSuggestedThreshold}
                initialValue={suggestedThreshold.value ?? 0}
                thresholdPredicate={thresholdPredicate}
                isResponsive={true}
                unit={notification.unit ?? ReportTemplateUnit.UrLs}
              />
            ) : null}
          </div>
          <div
            style={{
              width: 50,
              display: "flex",
              alignItems: "center",
              borderTopRightRadius: BORDER_RADIUS,
              borderBottomRightRadius: BORDER_RADIUS,
              justifyContent: "center",
            }}
            onMouseEnter={() => setShowArrowTooltip(true)}
            onMouseLeave={() => setShowArrowTooltip(false)}
          >
            {isMouseOver ? (
              <Tooltip
                title={urlData.tooltip}
                arrow={false}
                open={showArrowTooltip}
                placement="bottom"
              >
                <ArrowCircleRightSolid className={classes.arrowIcon} />
              </Tooltip>
            ) : null}
          </div>
        </div>
      </ConditionalLink>
    </>
  );
}

const doesHaveAnyRulesAndThresholds = (
  notificationType: MonitorNotificationType,
  data?: GetRulesAndThresholdsQuery,
): boolean => {
  if (notificationType === MonitorNotificationType.HealthScore)
    return Boolean(data?.alert?.healthScoreRulesAndThresholds.totalCount);
  return Boolean(data?.alert?.rulesAndThresholds?.totalCount);
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flex: 1,
    borderRadius: BORDER_RADIUS,
    marginTop: 1,
    marginBottom: 14,
    backgroundColor: "white",
    minHeight: 154.37,
    boxShadow:
      "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)",
    border: "1px solid transparent",
    "&:hover": {
      border: `1px solid ${theme.palette.grey[400]}`,
      marginTop: 1,
      marginBottom: 14,
      boxShadow:
        "0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04)",
    },
  },
  statusIconContainer: ({
    isFailed,
    isAdjustment,
  }: {
    isFailed: boolean;
    isAdjustment: boolean;
  }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.grey[50],
    borderTopLeftRadius: BORDER_RADIUS,
    borderBottomLeftRadius: BORDER_RADIUS,
    flexBasis: 76,
    "& svg": {
      fontSize: 36,
      color: isAdjustment
        ? theme.palette.green[400]
        : isFailed
          ? theme.palette.red[400]
          : theme.palette.yellow[400],
    },
  }),
  mainContentContainer: {
    flex: 1,
    minWidth: 0,
    padding: theme.spacing(2, 0, 2, 3),
  },
  secondaryText: {
    color: theme.palette.grey[500],
    display: "block",
  },
  arrowIcon: {
    color: theme.palette.ultraviolet[500],
    fontSize: 24,
  },
  suggestedThresholdChip: {
    height: 22,
    border: `1px solid ${theme.palette.grey[300]}`,
    backgroundColor: "white",
    marginRight: theme.spacing(1),
    color: theme.palette.grey[600],
    borderRadius: 8,
    "& .MuiChip-label": {
      paddingLeft: 6,
      paddingRight: 6,
      fontSize: theme.typography.pxToRem(13),
      lineHeight: theme.typography.pxToRem(14.3),
    },
  },
  updatedRuleText: {
    color: theme.palette.grey[500],
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(14.5),
  },
  statusChipsContainer: {
    display: "flex",
    alignItems: "center",
    padding: 5,
    marginRight: -5,
    marginBottom: -5,
    width: 350,
  },
  bluebackground: {
    border: `2px solid ${theme.palette.ultraviolet[500]}`,
    marginTop: 0,
    marginBottom: 13,
  },
  link: {
    textDecoration: "none",
    color: "inherit",
    flex: 1,
    width: "calc(100% - 46px)",
  },
  centralContainer: {
    display: "flex",
    flex: 1,
    ["@media (max-width: 1050px)"]: {
      display: "block",
    },
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    minWidth: 0,
  },
  segment: {
    borderRadius: 4,
    background: theme.palette.grey[200],
    display: "flex",
    height: 32,
    alignSelf: "center",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  segmentText: {
    overflowX: "hidden",
    textOverflow: "ellipsis",
    maxWidth: 300,
    whiteSpace: "nowrap",
  },
}));
