import { ReactNode, useState } from "react";
import {
  Alert,
  Dialog,
  ExclamationSolid,
  ExclamationCircleSolid,
  SelectableButtonGroup,
  Typography,
  useTranslation,
  useSession,
} from "@lumar/shared";
import {
  Table,
  TableCell,
  TableRow,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import {
  Maybe,
  ReportTemplateUnit,
  Severity,
  Test,
  TestAutoThresholdAcceptance,
  ThresholdPredicate,
} from "../../graphql";
import { ReportTrendInfo } from "./ReportTrendInfo";
import {
  ExtendedThresholdSettings,
  MonitorNotification,
  ThresholdSettings,
} from "../types";
import { ThresholdAcceptanceMenu } from "../../alerts/_common/ThresholdAcceptanceMenu";
import { TextFieldWithUnit } from "../../alerts/_common/TextFieldWithUnit";
import clsx from "clsx";
import { extractChartDataFromTrends } from "../utils/extractChartDataFromTrends";
import { extractChartDataFromTestResults } from "../utils/extractChartDataFromTestResults";

export type SaveRuleElement = Pick<
  Test,
  | "absoluteThreshold"
  | "severity"
  | "thresholdPredicate"
  | "automaticThresholdAcceptanceWhenTestResultIsWorse"
  | "automaticThresholdAcceptanceWhenTestResultIsBetter"
>;

export interface EditBaseRuleAndThresholdDialogProps {
  isOpen: boolean;
  onClose: () => void;
  notification: MonitorNotification;
  validate: (value: number) => string | undefined;
  loading?: boolean;
  handleSave: (test: SaveRuleElement) => void;
}

const haveRuleSettingsChanged = (
  settingsAtTimeOfCrawl: ThresholdSettings,
  currentSettings?: Maybe<ExtendedThresholdSettings>,
): boolean => {
  return (
    settingsAtTimeOfCrawl.absoluteThreshold !==
      currentSettings?.absoluteThreshold ||
    settingsAtTimeOfCrawl.severity !== currentSettings.severity ||
    settingsAtTimeOfCrawl.thresholdPredicate !==
      currentSettings.thresholdPredicate
  );
};

export function EditBaseRuleAndThresholdDialog({
  isOpen,
  onClose,
  notification,
  loading,
  validate,
  handleSave,
}: EditBaseRuleAndThresholdDialogProps): JSX.Element {
  const theme = useTheme();
  const classes = useStyles();

  const {
    account: {
      subscription: { segmentationAvailable },
    },
  } = useSession();

  const [haveValuesChanged, setHaveValuesChanged] = useState(false);
  const [severity, setSeverity] = useState<Severity>(
    notification.test?.severity ?? Severity.Fail,
  );
  const [thresholdPredicate, setThresholdPredicate] =
    useState<ThresholdPredicate>(
      notification.test?.thresholdPredicate ??
        ThresholdPredicate.GreaterThanOrEqual,
    );
  const [threshold, setThreshold] = useState<number>(
    notification.test?.absoluteThreshold ?? 100,
  );

  const [thresholdAcceptanceWorse, setThresholdAcceptanceWorse] = useState(
    notification.test?.automaticThresholdAcceptanceWhenTestResultIsWorse ??
      TestAutoThresholdAcceptance.None,
  );
  const [thresholdAcceptanceBetter, setThresholdAcceptanceBetter] = useState(
    notification.test?.automaticThresholdAcceptanceWhenTestResultIsBetter ??
      TestAutoThresholdAcceptance.None,
  );

  const { currentUrlCount, previousUrlCount, seriesData } =
    extractChartDataFromTrends(notification.stat?.trend);

  const thresholdSeriesData = extractChartDataFromTestResults(
    notification.stat?.testResults?.nodes,
    notification.type,
  );

  const ruleSettingsAtTimeOfCrawl: ThresholdSettings = {
    absoluteThreshold: notification.absoluteThreshold ?? 0,
    thresholdPredicate:
      notification.thresholdPredicate ?? ThresholdPredicate.LessThan,
    severity: notification.severity,
  };

  const report = notification.base;

  const { t } = useTranslation([
    "alerts",
    "common",
    "filterPredicates",
    "notifications",
  ]);

  const validationError = validate(Number(threshold));

  const canSaveChanges = haveValuesChanged && !Boolean(validationError);

  const SEVERITY_OPTIONS = [
    {
      value: Severity.Warning,
      label: t(Severity.Warning),
      hideLabel: true,
      tooltipTitle: t(`notifications:${Severity.Warning}`),
      "data-pendo": "edit-rule-and-threshold-dialog-warning-button",
      // eslint-disable-next-line react/display-name
      startIcon: (isSelected: boolean): ReactNode => (
        <ExclamationSolid
          style={{ fontSize: theme.typography.pxToRem(16) }}
          htmlColor={
            isSelected ? theme.palette.yellow[400] : theme.palette.grey[200]
          }
        />
      ),
    },
    {
      value: Severity.Fail,
      label: t(Severity.Fail),
      hideLabel: true,
      tooltipTitle: t(`notifications:${Severity.Fail}`),
      "data-pendo": "edit-rule-and-threshold-dialog-fail-button",
      // eslint-disable-next-line react/display-name
      startIcon: (isSelected: boolean): ReactNode => (
        <ExclamationCircleSolid
          style={{ fontSize: theme.typography.pxToRem(16) }}
          htmlColor={
            isSelected ? theme.palette.red[400] : theme.palette.grey[200]
          }
        />
      ),
    },
  ];

  const isCustomExtraction = report.code.startsWith("custom_extraction_");
  const reportTemplateName =
    (isCustomExtraction
      ? notification.stat?.customExtractions?.find(
          (e) => e.reportTemplateCode === report.code,
        )?.label
      : undefined) ?? report.name;

  return (
    <Dialog
      title={t("notificationsPage.editRule")}
      open={isOpen}
      data-testid="edit-rule-and-threshold-dialog"
      onCloseViaIcon={onClose}
      onClose={onClose}
      anchorEl={undefined}
      buttons={[
        {
          title: t("common:cancel"),
          variant: "outlined",
          onClick: onClose,
          "data-pendo": "edit-rule-and-threshold-dialog-cancel-button",
        },
        {
          title: loading ? t("common:saving") : t("common:saveChanges"),
          variant: "contained",
          color: "primary",
          onClick: () => {
            handleSave({
              severity,
              absoluteThreshold: threshold,
              thresholdPredicate,
              automaticThresholdAcceptanceWhenTestResultIsBetter:
                thresholdAcceptanceBetter,
              automaticThresholdAcceptanceWhenTestResultIsWorse:
                thresholdAcceptanceWorse,
            });
          },
          disabled: !canSaveChanges || loading,
          "data-pendo": "edit-rule-and-threshold-dialog-save-changes-button",
          "data-testid": "edit-rule-and-threshold-dialog-save-changes-button",
        },
      ]}
    >
      <div
        style={{
          display: "flex",
          flexFlow: "column",
          padding: "14px 8px 22px 8px",
        }}
        data-testid="edit-rule-dialog-content"
      >
        {haveRuleSettingsChanged(
          ruleSettingsAtTimeOfCrawl,
          notification.test,
        ) ? (
          <Alert
            severity="info"
            size="small"
            data-testid="changed-rule-and-threshold-info-message"
            style={{ marginBottom: 8 }}
          >
            <Typography
              variant="subtitle1SemiBold"
              style={{ fontSize: theme.typography.pxToRem(12) }}
            >
              {t("edited_fp")}
            </Typography>
            &nbsp;
            <Typography
              variant="subtitle1"
              style={{ fontSize: theme.typography.pxToRem(12) }}
            >
              {t("edited_sp")}
            </Typography>
          </Alert>
        ) : undefined}
        <Table className={classes.ruleAndThresholdContainer}>
          <TableRow
            style={{
              boxShadow: `0px -1px 0px 0px #E5E7EB inset, -1px 0px 0px 0px #E5E7EB inset,0px 1px 0px 0px #E5E7EB inset, 1px 0px 0px 0px #E5E7EB inset`,
              height: 56,
            }}
          >
            <TableCell className={classes.tableCell}>
              <SelectableButtonGroup
                value={severity}
                colorVariant="muted"
                onValueChange={(newSeverity) => {
                  setHaveValuesChanged(true);
                  setSeverity(newSeverity);
                }}
                options={SEVERITY_OPTIONS}
                dataTestId="severity-button-group"
                size="small"
              />
            </TableCell>
            <TableCell className={classes.tableCell}>
              <Typography
                className={classes.textCell}
                variant="body2"
                data-testid="edit-rule-and-threshold-report-name"
              >
                {reportTemplateName}
              </Typography>
            </TableCell>
            {segmentationAvailable && (
              <TableCell className={classes.tableCell}>
                <Typography
                  className={classes.textCell}
                  variant="body2"
                  data-testid="edit-rule-and-threshold-report-name"
                >
                  {notification.segment?.name ?? t("common:noSegments")}
                </Typography>
              </TableCell>
            )}
            <TableCell className={classes.tableCell}>
              <SelectableButtonGroup
                value={thresholdPredicate}
                colorVariant="muted"
                size="small"
                dataTestId="threshold-predicate-button-group"
                onValueChange={(newPred) => {
                  setHaveValuesChanged(true);
                  setThresholdPredicate(newPred);
                }}
                options={[
                  {
                    value: ThresholdPredicate.GreaterThanOrEqual,
                    label: "≥",
                    tooltipTitle: t(
                      "filterPredicates:greaterThanOrEqualTo",
                    ) as string,
                    "data-pendo": "edit-rule-and-threshold-dialog-gte-button",
                  },
                  {
                    value: ThresholdPredicate.LessThan,
                    label: "<",
                    tooltipTitle: t("filterPredicates:lessThan") as string,
                    "data-pendo": "edit-rule-and-threshold-dialog-lt-button",
                  },
                ]}
              />
            </TableCell>
            <TableCell className={classes.tableCell}>
              <TextFieldWithUnit
                variant="outlined"
                type="number"
                unit={t(
                  `units:${notification.base?.unit ?? ReportTemplateUnit.UrLs}`,
                  { count: threshold },
                )}
                value={threshold}
                error={Boolean(validationError)}
                className={classes.textField}
                helperText={validationError}
                data-pendo="edit-rule-and-threshold-dialog-url-threshold-input"
                data-testid="edit-rule-and-threshold-absolute-urls"
                onChange={(e) => {
                  setHaveValuesChanged(true);
                  setThreshold(Number(e.target.value));
                }}
                style={{ maxWidth: 100 }}
              />
            </TableCell>
            <TableCell className={clsx(classes.tableCell, classes.issuesCell)}>
              <ThresholdAcceptanceMenu
                value={thresholdAcceptanceWorse}
                onChange={(e) => {
                  setHaveValuesChanged(true);
                  setThresholdAcceptanceWorse(
                    e.target.value as TestAutoThresholdAcceptance,
                  );
                }}
                label={t("rulesAndThresholds.thresholdSettingWhenWorse")}
                subheader={t("rulesAndThresholds.ifIssueWorsens")}
                data-pendo={"edit-threshold-acceptance-when-worse"}
                data-testid={"edit-threshold-acceptance-when-worse"}
              />
            </TableCell>
            <TableCell className={clsx(classes.tableCell, classes.issuesCell)}>
              <ThresholdAcceptanceMenu
                value={thresholdAcceptanceBetter}
                onChange={(e) => {
                  setHaveValuesChanged(true);
                  setThresholdAcceptanceBetter(
                    e.target.value as TestAutoThresholdAcceptance,
                  );
                }}
                label={t("rulesAndThresholds.thresholdSettingWhenBetter")}
                subheader={t("rulesAndThresholds.ifIssueImproves")}
                data-pendo={"edit-threshold-acceptance-when-better"}
                data-testid={"edit-threshold-acceptance-when-better"}
              />
            </TableCell>
            <TableCell className={classes.tableCell}>
              <ReportTrendInfo
                unit={notification.base.unit}
                currentUrlCount={currentUrlCount}
                previousUrlCount={previousUrlCount}
                totalSign={Math.sign(
                  notification.stat?.weight ?? notification.base.sign ?? 0,
                )}
                reportTrendData={seriesData}
                thresholdTrendData={thresholdSeriesData}
                absoluteThreshold={Number(threshold)}
                edited={haveValuesChanged}
              />
            </TableCell>
          </TableRow>
        </Table>
      </div>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  formControlLabel: {
    marginRight: 0,
    "& [class*=MuiFormControlLabel-label]": {
      marginRight: 12,
      fontWeight: "inherit",
    },
  },
  ruleAndThresholdContainer: {
    padding: theme.spacing(1),
    borderRadius: 8,
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.grey[700],
  },
  suggestedThresholdContainer: {
    borderRadius: 8,
    border: `1px solid ${theme.palette.grey[200]}`,
    padding: 5,
    margin: "-6px 0px",
    marginRight: theme.spacing(5),
  },
  textField: {
    "& input": {
      height: 17,
      paddingTop: "6px!important",
      paddingBottom: "6px!important",
    },
    "& .Mui-disabled": {
      color: theme.palette.grey[400],
    },
    minWidth: 150,
  },
  tableCell: {
    boxShadow: `-1px 0px 0px 0px #E5E7EB inset`,
    padding: theme.spacing(1),
  },
  issuesCell: {
    background: "#EBEFF3",
  },
  textCell: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: 200,
    overflowX: "hidden",
    minWidth: 100,
    textAlign: "center",
  },
}));
